.send-page {
  background-color: #010006db;
  min-height: calc(100vh - 3rem);
  padding: 1.5rem 1rem;
  color: #fff;
}
.send-page .header {
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
}
.send-page .header button {
  border: unset;
  background-color: unset;
  color: #fff;
  outline: unset;
  border-radius: 10px;
  padding: 0.5rem;
  font-weight: 700;
}
.send-page .form {
  margin-top: 0.5rem;
}
.send-page .form label {
  font-size: 13px;
  color: #ddd;
}
.send-page .form input {
  display: block;
  width: calc(100% - 1.7rem);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 13px;
}
.send-page .value {
  font-size: 10px;
  text-align: center;
  margin-top: 0.5rem;
}
.send-page .value pre {
  font-size: 12px;
  margin: 5px;
}
.send-page .are-you-sure {
  font-size: 0.8rem;
  text-align: justify;
}
.send-page .transaction-done .heading span {
  font-size: 1.1rem;
  text-align: center;
  margin-top: 0.5rem;
  display: block;
}
.send-page .transaction-done .form {
  margin-top: 1.5rem;
  text-align: center;
}
.send-page .transaction-done .form p {
  font-size: 0.9rem;
}
.send-page .transaction-done button {
  border: unset;
  background: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 8px 0;
  font-weight: 700;
}
.send-page .navigation {
  margin-top: 10px;
  width: calc(100vw - 1.8rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.send-page .navigation button {
  border: unset;
  background-color: transparent;
  background: transparent;
  font-size: 1rem;
  color: #0d6efd;
  padding: 0.5rem;
  border-radius: 10px;
}
.scanner-div .container {
  border-radius: 16px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);
  position: relative;
  padding-top: 1rem;
  margin-top: 0.5rem;
}
.scanner-div .container .larr {
  font-size: 1.5rem;
}
.scanner-div .heading-scanner {
  display: flex;
  align-items: center;
}
.scanner-div .heading-scanner h3 {
  display: inline-block;
  margin: 0;
  margin-left: 10px;
}
.scanner-div .camera {
  margin: 8px 0px;
}
.scanner-div .camera video {
  width: calc(100vw - 50px);
}
.scanner-div .btn {
  text-align: center;
  z-index: 10;
  margin-top: 8px;
}
.scanner-div .btn button {
  width: 100%;
  border: none;
  padding: 7px 10px;
  background-color: #0d6efd;
  color: #fff;
  font-weight: 600;
  border-radius: 0.3rem;
  font-size: 0.7rem;
  text-align: center;
  vertical-align: middle;
}
.showError {
  overflow-x: hidden;
}
.showError .heading {
  font-size: 1.1rem;
  text-align: center;
  margin-top: 10px;
}
.showError .error-img {
  margin: auto;
  height: 100px;
  width: 100px;
  margin-top: 15px;
}
.showError .error-img img {
  width: 100%;
  height: 100%;
}
.showError p {
  width: 100%;
  word-break: break-all;
}
.showError .button {
  border: unset;
  background: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 8px 0;
  font-weight: 700;
  color: #000;
}
