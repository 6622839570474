.show-mnemonic-page {
  background-color: #010006db;
  min-height: calc(100vh - 2rem);
  padding: 1.5rem 0.9rem;
  padding-bottom: 0.5rem;
  overflow: hidden;
  color: #fff;
  text-align: center;
}
.show-mnemonic-page p {
  font-size: 13px;
}
.show-mnemonic-page .mnemonic {
  display: grid;
  grid-template-columns: auto auto;
}
.show-mnemonic-page .mnemonic .mnemonic-word {
  border: 1px solid white;
  margin: 10px;
  padding: 5px 0;
  border-radius: 5px;
}
.show-mnemonic-page .mnemonic .mnemonic-word p {
  font-size: 12px;
  margin: 1px 0px 0px;
}
.show-mnemonic-page .warning-inst {
  font-size: 0.85rem;
  text-align: justify;
}
.show-mnemonic-page .warning-inst span {
  color: red;
}
.show-mnemonic-page .navigation {
  width: calc(100vw - 1.8rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.show-mnemonic-page .navigation button {
  border: unset;
  background-color: transparent;
  background: transparent;
  font-size: 1rem;
  color: #0d6efd;
  padding: 0.5rem;
  border-radius: 10px;
}
