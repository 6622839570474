.transaction-details {
  background-color: #010006db;
  min-height: calc(100vh - 2rem);
  padding: 1.5rem 0.9rem;
  overflow: hidden;
  padding-top: 0.5rem;
  color: #fff;
}
.transaction-details .header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.transaction-details .header .logo {
  width: 40px;
  height: 40px;
}
.transaction-details .header .logo img {
  height: 100%;
  width: 100%;
}
.transaction-details .header p {
  margin-left: 6px;
  font-weight: 500;
  text-transform: uppercase;
}
.transaction-details .content .title span {
  margin: 7px 0;
  display: block;
}
.transaction-details .content .data {
  margin: 5px 0;
}
.transaction-details .content .data small {
  margin: 0;
}
.transaction-details .content .data p {
  font-size: 0.8rem;
  margin: 0;
  text-align: justify;
  word-wrap: break-word;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 3px;
}
.transaction-details .navigation {
  margin-top: 10px;
  width: calc(100vw - 1.8rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.transaction-details .navigation button {
  border: unset;
  background-color: transparent;
  background: transparent;
  font-size: 1rem;
  color: #0d6efd;
  padding: 0.5rem;
  border-radius: 10px;
}
