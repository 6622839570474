.transactions-page {
  background-color: #010006db;
  min-height: calc(100vh - 2.5rem);
  height: calc(100vh - 1.5rem);
  padding: 1.5rem 0rem;
  overflow: hidden;
  color: #fff;
  padding-top: 0rem;
}
.transactions-page .header {
  background-color: #252424;
  height: 35vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.transactions-page .header pre {
  font-weight: 500;
  font-size: 1.4rem;
}
.transactions-page .header button {
  font-size: 14px;
  text-decoration: none;
  position: fixed;
  top: 19px;
  left: 15px;
  color: white;
  background: transparent;
  border: unset;
  padding: 5px;
  border-radius: 10px;
}
.transactions-page .transactions-container {
  background-color: #252424;
  height: 65vh;
  color: white;
  overflow-y: scroll;
  box-shadow: #1e1818 0px 0px 10px;
}
.transactions-page .transactions-container .transaction label {
  font-size: 9px;
  color: #dcdede;
  padding: 6px 0px 0px 10px;
  margin-bottom: 0.5rem;
  display: inline-block;
}
.transactions-page .transactions-container .transaction .data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0d6efd;
  padding: 10px 25px 0px;
  text-align: center;
}
.transactions-page .transactions-container .transaction .data .left svg {
  font-size: 13px;
}
.transactions-page .transactions-container .transaction .data .left pre {
  font-size: 10px;
  margin-top: 0;
}
.transactions-page .transactions-container .transaction .data .center {
  font-size: 0.9rem;
}
.transactions-page .transactions-container .transaction .data .right {
  font-size: 14px;
}
.transactions-page .transactions-container .transaction .data .right button {
  background: transparent;
  border: unset;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}
.transactions-page .transactions-container .transaction:last-child {
  margin-bottom: 25px;
}
