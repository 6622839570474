.home-page {
  background-color: #010006db;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  text-align: center;
}
.home-page .branding .home-inner {
  display: flex;
  align-items: center;
  line-height: 1.2;
}
.home-page .home-inner {
  display: flex;
  flex-direction: column;
}
.home-page .home-inner .logo {
  width: 80px;
  height: 80px;
  text-align: center;
}
.home-page .home-inner .logo img {
  width: 100%;
  height: 100%;
}
.home-page .home-inner p {
  text-transform: uppercase;
  font-size: 0.9rem;
  color: #fff;
  text-align: justify;
}
.home-page .home-inner p .first {
  font-size: 1.2rem;
}
.home-page button {
  border: unset;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
}
.home-page .new-wallet-btn {
  background-color: #0d6efd;
  color: #fff;
  padding-left: 25px !important;
  padding-right: 25px !important;
  margin: auto;
  width: 87%;
}
.home-page .restore-wallet-btn {
  background: transparent;
  color: #fff;
  margin-top: 0.5rem;
}
