.wallet-page {
  background-color: #010006db;
  min-height: calc(100vh - 0.5rem);
  padding-bottom: 0.5rem;
  overflow: hidden;
  color: #fff;
  text-align: center;
}
.wallet-page .wallet-header {
  background-color: #0d6efd;
  width: 100vw;
  height: 45vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wallet-page .wallet-header div {
  font-size: 1.3rem;
}
.wallet-page .wallet-header div pre {
  margin-bottom: 0;
  margin-top: 0.5rem;
}
.wallet-page .wallet-header div .address {
  word-break: break-all;
  font-size: 0.9rem;
  margin: auto;
  margin-top: 1.5rem;
  margin-left: 10px;
  margin-right: 10px;
}
.wallet-page .wallet-header button {
  background-color: transparent;
  background: transparent;
  border: unset;
  position: absolute;
  right: 12px;
  top: 12px;
  border-radius: 10px;
  padding: 0.5rem;
}
.wallet-page .wallet-header button svg {
  color: #fff;
  font-size: 18px;
  background: transparent;
}
.wallet-page .wallet-middle button {
  background-color: transparent !important;
  background: transparent !important;
  border: unset;
  color: #fff;
  font-size: 14px;
  margin-top: 1rem;
  padding: 0.375rem 0.75rem;
  border-radius: 0.5rem;
}
.wallet-page .wallet-middle button svg {
  color: #0d6efd;
  vertical-align: middle;
}
.wallet-page .wallet-send-receive {
  width: 100vw;
  height: 35vh;
  display: flex;
  align-items: end;
  justify-content: center;
}
.wallet-page .wallet-send-receive span {
  font-size: 0.8rem;
  text-align: center;
}
.wallet-page .wallet-send-receive button {
  border-radius: 50%;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: #0d6efd;
  border: unset;
}
.wallet-page .wallet-send-receive button svg {
  color: #fff;
}
