.existing-wallet-page {
  background-color: #010006db;
  min-height: calc(100vh - 3rem);
  padding: 1.5rem 0.9rem;
  overflow: hidden;
  color: #fff;
}
.existing-wallet-page .back-btn svg {
  color: #0d6efd;
  padding: 5px;
  border-radius: 10px;
}
.existing-wallet-page p {
  text-align: center;
  margin-top: 2rem;
  padding: 1rem 0;
}
.existing-wallet-page button {
  background-color: transparent;
  background: transparent;
  border: unset;
  color: #fff;
  font-size: 1rem;
  padding: 5px;
  border-radius: 10px;
}
.existing-wallet-page button svg {
  color: #0d6efd;
  vertical-align: middle;
}
