.receive-page {
  background-color: #010006db;
  min-height: calc(100vh - 2rem);
  padding: 1.5rem 0.9rem;
  overflow: hidden;
  padding-top: 0.5rem;
  color: #fff;
}
.receive-page .header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.receive-page .header .logo {
  width: 40px;
  height: 40px;
}
.receive-page .header .logo img {
  height: 100%;
  width: 100%;
}
.receive-page .header p {
  margin-left: 6px;
  font-weight: 500;
  text-transform: uppercase;
}
.receive-page .content .title {
  font-weight: 500;
  text-align: center;
  margin-bottom: 8px;
}
.receive-page .content .qr-code {
  width: 70%;
  height: 70%;
  margin: auto;
}
.receive-page .content .bottom {
  margin-top: 6px;
}
.receive-page .content .bottom p {
  border: 1px solid #fff;
  width: 80%;
  margin: auto;
  border-radius: 5px;
  padding: 5px;
  margin-top: 2px;
  word-wrap: break-word;
  font-size: 0.9rem;
}
.receive-page .navigation {
  margin-top: 10px;
  width: calc(100vw - 1.8rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.receive-page .navigation button {
  border: unset;
  background-color: transparent;
  background: transparent;
  font-size: 1rem;
  color: #0d6efd;
  padding: 0.5rem;
  border-radius: 10px;
}
