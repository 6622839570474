.external-wallet-page {
  background-color: #010006db;
  min-height: calc(100vh - 3rem);
  padding: 1.5rem 0.9rem;
  overflow: hidden;
  color: #fff;
  text-align: center;
}
.external-wallet-page p {
  margin: 2rem 0;
}
.external-wallet-page .form {
  margin-top: 0.5rem;
}
.external-wallet-page .form input {
  display: block;
  width: calc(100% - 2rem);
  margin: auto;
  margin-top: 0.5rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 13px;
}
.external-wallet-page .navigation {
  margin-top: 10px;
  width: calc(100vw - 1.8rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.external-wallet-page .navigation button {
  border: unset;
  background-color: transparent;
  background: transparent;
  font-size: 1rem;
  color: #0d6efd;
  padding: 0.5rem;
  border-radius: 10px;
}
