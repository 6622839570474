.export-key-page {
  background-color: #010006db;
  min-height: calc(100vh - 2rem);
  padding: 1.5rem 0.9rem;
  overflow: hidden;
  padding-top: 0.5rem;
  color: #fff;
}
.export-key-page .header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.export-key-page .header button {
  font-size: 14px;
  text-decoration: none;
  color: white;
  background: transparent;
  border: unset;
  padding: 5px;
  border-radius: 10px;
}
.export-key-page .header .logo {
  width: 40px;
  height: 40px;
}
.export-key-page .header .logo img {
  height: 100%;
  width: 100%;
}
.export-key-page .header p {
  margin-left: 6px;
  font-weight: 500;
  text-transform: uppercase;
}
.export-key-page .content p {
  word-wrap: break-word;
  text-align: center;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}
.export-key-page .content .form input {
  border-radius: 5px;
  width: 100%;
  border: 1px solid #fff;
  text-align: center;
  padding: 8px 0;
  background: transparent;
  color: #fff;
}
.export-key-page .content .form button {
  margin-top: 12px;
  border: unset;
  background: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 8px 0;
}
.export-key-page .content .form .data {
  margin: 5px 0;
}
.export-key-page .content .form .data small {
  margin: 0;
}
.export-key-page .content .form .data p {
  font-size: 0.8rem;
  margin: 0;
  text-align: justify;
  word-wrap: break-word;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 3px;
}
.export-key-page .navigation {
  margin-top: 10px;
  width: calc(100vw - 1.8rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.export-key-page .navigation button {
  border: unset;
  background-color: transparent;
  background: transparent;
  font-size: 1rem;
  color: #0d6efd;
  padding: 0.5rem;
  border-radius: 10px;
}
