.connect-ton-page {
  background-color: #010006db;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  text-align: center;
}
.connect-ton-page .connect-ton-page-inner {
  width: 80%;
  display: flex;
  flex-direction: column;
}
.connect-ton-page .connect-ton-page-inner .logos {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
.connect-ton-page .connect-ton-page-inner .logos .logo {
  width: 50px;
  height: 50px;
  text-align: center;
}
.connect-ton-page .connect-ton-page-inner .logos .logo img {
  width: 100%;
  height: 100%;
}
.connect-ton-page .connect-ton-page-inner .logos .logo.arrow img {
  transform: rotate(180deg);
  filter: invert(100%);
}
.connect-ton-page .connect-ton-page-inner .summary {
  margin-bottom: 10px;
}
.connect-ton-page .connect-ton-page-inner .summary .heading {
  font-size: 1.1rem;
  color: #fff;
}
.connect-ton-page .connect-ton-page-inner .summary p {
  word-break: break-all;
  font-size: 0.8rem;
  color: #fff;
  text-align: start;
  margin: 5px 0;
}
.connect-ton-page .connect-ton-page-inner .form input {
  border-radius: 5px;
  width: 100%;
  border: 1px solid #fff;
  text-align: center;
  padding: 8px 0;
  background: transparent;
  color: #fff;
}
.connect-ton-page .connect-ton-page-inner .form button {
  margin-top: 12px;
  border: unset;
  background: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 8px 0;
}
.connect-ton-page .connect-ton-page-inner .form p {
  font-size: 1rem;
  color: #fff;
}
.connect-ton-page button {
  border: unset;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
}
.connect-ton-page .connect-wallet-btn {
  background-color: #0d6efd;
  color: #fff;
  padding-left: 25px !important;
  padding-right: 25px !important;
  margin: auto;
}
.connect-ton-page .cancel-btn {
  background: transparent;
  color: #fff;
  margin-top: 0.5rem;
}
