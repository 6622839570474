.create-wallet-page {
  background-color: #010006db;
  min-height: calc(100vh - 3rem);
  padding: 1.5rem 0.9rem;
  overflow: hidden;
  color: #fff;
}
.create-wallet-page .form {
  margin-top: 0.5rem;
}
.create-wallet-page .form label {
  font-size: 13px;
  color: #ddd;
}
.create-wallet-page .form input[type="password"] {
  display: block;
  width: calc(100% - 1.7rem);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 13px;
}
.create-wallet-page .form input[type="checkbox"] {
  width: 10px;
  border-radius: 0.25em;
  margin: 0;
  vertical-align: middle;
}
.create-wallet-page .form .checkbox {
  margin-top: 1rem;
  font-size: 10px;
  text-align: justify;
}
.create-wallet-page .form .checkbox span {
  color: #ddd;
  margin-left: 6px;
}
.create-wallet-page .navigation {
  margin-top: 10px;
  width: calc(100vw - 1.8rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.create-wallet-page .navigation button {
  border: unset;
  background-color: transparent;
  background: transparent;
  font-size: 1rem;
  color: #0d6efd;
  padding: 0.5rem;
  border-radius: 10px;
}
