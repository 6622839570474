body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body{
  line-height: 1.5;
  box-sizing: border-box;
}

input:focus{
  border: 1px solid #0d6efd !important;
  outline: unset;
}
.items:focus {
  box-shadow: 1px 1px 12px 2px #fff !important;
  outline: none;
}


.home-page {
  background-color: #010006db;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  text-align: center;
}
.home-page .branding .home-inner {
  display: flex;
  align-items: center;
  line-height: 1.2;
}
.home-page .home-inner {
  display: flex;
  flex-direction: column;
}
.home-page .home-inner .logo {
  width: 80px;
  height: 80px;
  text-align: center;
}
.home-page .home-inner .logo img {
  width: 100%;
  height: 100%;
}
.home-page .home-inner p {
  text-transform: uppercase;
  font-size: 0.9rem;
  color: #fff;
  text-align: justify;
}
.home-page .home-inner p .first {
  font-size: 1.2rem;
}
.home-page button {
  border: unset;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
}
.home-page .new-wallet-btn {
  background-color: #0d6efd;
  color: #fff;
  padding-left: 25px !important;
  padding-right: 25px !important;
  margin: auto;
  width: 87%;
}
.home-page .restore-wallet-btn {
  background: transparent;
  color: #fff;
  margin-top: 0.5rem;
}

.create-wallet-page {
  background-color: #010006db;
  min-height: calc(100vh - 3rem);
  padding: 1.5rem 0.9rem;
  overflow: hidden;
  color: #fff;
}
.create-wallet-page .form {
  margin-top: 0.5rem;
}
.create-wallet-page .form label {
  font-size: 13px;
  color: #ddd;
}
.create-wallet-page .form input[type="password"] {
  display: block;
  width: calc(100% - 1.7rem);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 13px;
}
.create-wallet-page .form input[type="checkbox"] {
  width: 10px;
  border-radius: 0.25em;
  margin: 0;
  vertical-align: middle;
}
.create-wallet-page .form .checkbox {
  margin-top: 1rem;
  font-size: 10px;
  text-align: justify;
}
.create-wallet-page .form .checkbox span {
  color: #ddd;
  margin-left: 6px;
}
.create-wallet-page .navigation {
  margin-top: 10px;
  width: calc(100vw - 1.8rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.create-wallet-page .navigation button {
  border: unset;
  background-color: transparent;
  background: transparent;
  font-size: 1rem;
  color: #0d6efd;
  padding: 0.5rem;
  border-radius: 10px;
}

.existing-wallet-page {
  background-color: #010006db;
  min-height: calc(100vh - 3rem);
  padding: 1.5rem 0.9rem;
  overflow: hidden;
  color: #fff;
}
.existing-wallet-page .back-btn svg {
  color: #0d6efd;
  padding: 5px;
  border-radius: 10px;
}
.existing-wallet-page p {
  text-align: center;
  margin-top: 2rem;
  padding: 1rem 0;
}
.existing-wallet-page button {
  background-color: transparent;
  background: transparent;
  border: unset;
  color: #fff;
  font-size: 1rem;
  padding: 5px;
  border-radius: 10px;
}
.existing-wallet-page button svg {
  color: #0d6efd;
  vertical-align: middle;
}

.external-wallet-page {
  background-color: #010006db;
  min-height: calc(100vh - 3rem);
  padding: 1.5rem 0.9rem;
  overflow: hidden;
  color: #fff;
  text-align: center;
}
.external-wallet-page p {
  margin: 2rem 0;
}
.external-wallet-page .form {
  margin-top: 0.5rem;
}
.external-wallet-page .form input {
  display: block;
  width: calc(100% - 2rem);
  margin: auto;
  margin-top: 0.5rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 13px;
}
.external-wallet-page .navigation {
  margin-top: 10px;
  width: calc(100vw - 1.8rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.external-wallet-page .navigation button {
  border: unset;
  background-color: transparent;
  background: transparent;
  font-size: 1rem;
  color: #0d6efd;
  padding: 0.5rem;
  border-radius: 10px;
}

.check-password-page {
  background-color: #010006db;
  min-height: calc(100vh - 3rem);
  padding: 1.5rem 0.9rem;
  overflow: hidden;
  color: #fff;
}
.check-password-page .form {
  margin-top: 0.5rem;
}
.check-password-page .form label {
  font-size: 13px;
  color: #ddd;
}
.check-password-page .form input {
  display: block;
  width: calc(100% - 1.7rem);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 13px;
}
.check-password-page .form .checkbox {
  margin-top: 1rem;
  font-size: 10px;
  text-align: justify;
}
.check-password-page .form .checkbox span {
  color: #ddd;
}
.check-password-page .navigation {
  margin-top: 10px;
  width: calc(100vw - 1.8rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.check-password-page .navigation button {
  border: unset;
  background-color: transparent;
  background: transparent;
  font-size: 1rem;
  color: #0d6efd;
  padding: 0.5rem;
  border-radius: 10px;
}

.show-mnemonic-page {
  background-color: #010006db;
  min-height: calc(100vh - 2rem);
  padding: 1.5rem 0.9rem;
  padding-bottom: 0.5rem;
  overflow: hidden;
  color: #fff;
  text-align: center;
}
.show-mnemonic-page p {
  font-size: 13px;
}
.show-mnemonic-page .mnemonic {
  display: grid;
  grid-template-columns: auto auto;
}
.show-mnemonic-page .mnemonic .mnemonic-word {
  border: 1px solid white;
  margin: 10px;
  padding: 5px 0;
  border-radius: 5px;
}
.show-mnemonic-page .mnemonic .mnemonic-word p {
  font-size: 12px;
  margin: 1px 0px 0px;
}
.show-mnemonic-page .warning-inst {
  font-size: 0.85rem;
  text-align: justify;
}
.show-mnemonic-page .warning-inst span {
  color: red;
}
.show-mnemonic-page .navigation {
  width: calc(100vw - 1.8rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.show-mnemonic-page .navigation button {
  border: unset;
  background-color: transparent;
  background: transparent;
  font-size: 1rem;
  color: #0d6efd;
  padding: 0.5rem;
  border-radius: 10px;
}

.verify-mnemonic-page {
  background-color: #010006db;
  min-height: calc(100vh - 2rem);
  padding: 1.5rem 0.9rem;
  padding-bottom: 0.5rem;
  overflow: hidden;
  color: #fff;
  text-align: center;
}
.verify-mnemonic-page .form {
  margin-top: 2rem;
  text-align: left;
  padding: 1rem;
  padding-right: 0;
  display: grid;
  grid-template-columns: auto auto;
}
.verify-mnemonic-page .form .form-check {
  display: block;
  min-height: 1.5rem;
  margin-bottom: 0.125rem;
}
.verify-mnemonic-page .form .form-check label {
  display: inline-block;
  margin-left: 7px;
}
.verify-mnemonic-page .form .form-check .form-check-input[type="radio"] {
  border-radius: 50%;
}
.verify-mnemonic-page .navigation {
  width: calc(100vw - 1.8rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.verify-mnemonic-page .navigation button {
  border: unset;
  background-color: transparent;
  background: transparent;
  font-size: 1rem;
  color: #0d6efd;
  padding: 0.5rem;
  border-radius: 10px;
}

.wallet-page {
  background-color: #010006db;
  min-height: calc(100vh - 0.5rem);
  padding-bottom: 0.5rem;
  overflow: hidden;
  color: #fff;
  text-align: center;
}
.wallet-page .wallet-header {
  background-color: #0d6efd;
  width: 100vw;
  height: 45vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wallet-page .wallet-header div {
  font-size: 1.3rem;
}
.wallet-page .wallet-header div pre {
  margin-bottom: 0;
  margin-top: 0.5rem;
}
.wallet-page .wallet-header div .address {
  word-break: break-all;
  font-size: 0.9rem;
  margin: auto;
  margin-top: 1.5rem;
  margin-left: 10px;
  margin-right: 10px;
}
.wallet-page .wallet-header button {
  background-color: transparent;
  background: transparent;
  border: unset;
  position: absolute;
  right: 12px;
  top: 12px;
  border-radius: 10px;
  padding: 0.5rem;
}
.wallet-page .wallet-header button svg {
  color: #fff;
  font-size: 18px;
  background: transparent;
}
.wallet-page .wallet-middle button {
  background-color: transparent !important;
  background: transparent !important;
  border: unset;
  color: #fff;
  font-size: 14px;
  margin-top: 1rem;
  padding: 0.375rem 0.75rem;
  border-radius: 0.5rem;
}
.wallet-page .wallet-middle button svg {
  color: #0d6efd;
  vertical-align: middle;
}
.wallet-page .wallet-send-receive {
  width: 100vw;
  height: 35vh;
  display: flex;
  align-items: end;
  justify-content: center;
}
.wallet-page .wallet-send-receive span {
  font-size: 0.8rem;
  text-align: center;
}
.wallet-page .wallet-send-receive button {
  border-radius: 50%;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: #0d6efd;
  border: unset;
}
.wallet-page .wallet-send-receive button svg {
  color: #fff;
}

.receive-page {
  background-color: #010006db;
  min-height: calc(100vh - 2rem);
  padding: 1.5rem 0.9rem;
  overflow: hidden;
  padding-top: 0.5rem;
  color: #fff;
}
.receive-page .header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.receive-page .header .logo {
  width: 40px;
  height: 40px;
}
.receive-page .header .logo img {
  height: 100%;
  width: 100%;
}
.receive-page .header p {
  margin-left: 6px;
  font-weight: 500;
  text-transform: uppercase;
}
.receive-page .content .title {
  font-weight: 500;
  text-align: center;
  margin-bottom: 8px;
}
.receive-page .content .qr-code {
  width: 70%;
  height: 70%;
  margin: auto;
}
.receive-page .content .bottom {
  margin-top: 6px;
}
.receive-page .content .bottom p {
  border: 1px solid #fff;
  width: 80%;
  margin: auto;
  border-radius: 5px;
  padding: 5px;
  margin-top: 2px;
  word-wrap: break-word;
  font-size: 0.9rem;
}
.receive-page .navigation {
  margin-top: 10px;
  width: calc(100vw - 1.8rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.receive-page .navigation button {
  border: unset;
  background-color: transparent;
  background: transparent;
  font-size: 1rem;
  color: #0d6efd;
  padding: 0.5rem;
  border-radius: 10px;
}

.transaction-details {
  background-color: #010006db;
  min-height: calc(100vh - 2rem);
  padding: 1.5rem 0.9rem;
  overflow: hidden;
  padding-top: 0.5rem;
  color: #fff;
}
.transaction-details .header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.transaction-details .header .logo {
  width: 40px;
  height: 40px;
}
.transaction-details .header .logo img {
  height: 100%;
  width: 100%;
}
.transaction-details .header p {
  margin-left: 6px;
  font-weight: 500;
  text-transform: uppercase;
}
.transaction-details .content .title span {
  margin: 7px 0;
  display: block;
}
.transaction-details .content .data {
  margin: 5px 0;
}
.transaction-details .content .data small {
  margin: 0;
}
.transaction-details .content .data p {
  font-size: 0.8rem;
  margin: 0;
  text-align: justify;
  word-wrap: break-word;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 3px;
}
.transaction-details .navigation {
  margin-top: 10px;
  width: calc(100vw - 1.8rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.transaction-details .navigation button {
  border: unset;
  background-color: transparent;
  background: transparent;
  font-size: 1rem;
  color: #0d6efd;
  padding: 0.5rem;
  border-radius: 10px;
}

.export-key-page {
  background-color: #010006db;
  min-height: calc(100vh - 2rem);
  padding: 1.5rem 0.9rem;
  overflow: hidden;
  padding-top: 0.5rem;
  color: #fff;
}
.export-key-page .header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.export-key-page .header button {
  font-size: 14px;
  text-decoration: none;
  color: white;
  background: transparent;
  border: unset;
  padding: 5px;
  border-radius: 10px;
}
.export-key-page .header .logo {
  width: 40px;
  height: 40px;
}
.export-key-page .header .logo img {
  height: 100%;
  width: 100%;
}
.export-key-page .header p {
  margin-left: 6px;
  font-weight: 500;
  text-transform: uppercase;
}
.export-key-page .content p {
  word-wrap: break-word;
  text-align: center;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}
.export-key-page .content .form input {
  border-radius: 5px;
  width: 100%;
  border: 1px solid #fff;
  text-align: center;
  padding: 8px 0;
  background: transparent;
  color: #fff;
}
.export-key-page .content .form button {
  margin-top: 12px;
  border: unset;
  background: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 8px 0;
}
.export-key-page .content .form .data {
  margin: 5px 0;
}
.export-key-page .content .form .data small {
  margin: 0;
}
.export-key-page .content .form .data p {
  font-size: 0.8rem;
  margin: 0;
  text-align: justify;
  word-wrap: break-word;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 3px;
}
.export-key-page .navigation {
  margin-top: 10px;
  width: calc(100vw - 1.8rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.export-key-page .navigation button {
  border: unset;
  background-color: transparent;
  background: transparent;
  font-size: 1rem;
  color: #0d6efd;
  padding: 0.5rem;
  border-radius: 10px;
}

.transactions-page {
  background-color: #010006db;
  min-height: calc(100vh - 2.5rem);
  height: calc(100vh - 1.5rem);
  padding: 1.5rem 0rem;
  overflow: hidden;
  color: #fff;
  padding-top: 0rem;
}
.transactions-page .header {
  background-color: #252424;
  height: 35vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.transactions-page .header pre {
  font-weight: 500;
  font-size: 1.4rem;
}
.transactions-page .header button {
  font-size: 14px;
  text-decoration: none;
  position: fixed;
  top: 19px;
  left: 15px;
  color: white;
  background: transparent;
  border: unset;
  padding: 5px;
  border-radius: 10px;
}
.transactions-page .transactions-container {
  background-color: #252424;
  height: 65vh;
  color: white;
  overflow-y: scroll;
  box-shadow: #1e1818 0px 0px 10px;
}
.transactions-page .transactions-container .transaction label {
  font-size: 9px;
  color: #dcdede;
  padding: 6px 0px 0px 10px;
  margin-bottom: 0.5rem;
  display: inline-block;
}
.transactions-page .transactions-container .transaction .data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0d6efd;
  padding: 10px 25px 0px;
  text-align: center;
}
.transactions-page .transactions-container .transaction .data .left svg {
  font-size: 13px;
}
.transactions-page .transactions-container .transaction .data .left pre {
  font-size: 10px;
  margin-top: 0;
}
.transactions-page .transactions-container .transaction .data .center {
  font-size: 0.9rem;
}
.transactions-page .transactions-container .transaction .data .right {
  font-size: 14px;
}
.transactions-page .transactions-container .transaction .data .right button {
  background: transparent;
  border: unset;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}
.transactions-page .transactions-container .transaction:last-child {
  margin-bottom: 25px;
}

.send-page {
  background-color: #010006db;
  min-height: calc(100vh - 3rem);
  padding: 1.5rem 1rem;
  color: #fff;
}
.send-page .header {
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
}
.send-page .header button {
  border: unset;
  background-color: unset;
  color: #fff;
  outline: unset;
  border-radius: 10px;
  padding: 0.5rem;
  font-weight: 700;
}
.send-page .form {
  margin-top: 0.5rem;
}
.send-page .form label {
  font-size: 13px;
  color: #ddd;
}
.send-page .form input {
  display: block;
  width: calc(100% - 1.7rem);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 13px;
}
.send-page .value {
  font-size: 10px;
  text-align: center;
  margin-top: 0.5rem;
}
.send-page .value pre {
  font-size: 12px;
  margin: 5px;
}
.send-page .are-you-sure {
  font-size: 0.8rem;
  text-align: justify;
}
.send-page .transaction-done .heading span {
  font-size: 1.1rem;
  text-align: center;
  margin-top: 0.5rem;
  display: block;
}
.send-page .transaction-done .form {
  margin-top: 1.5rem;
  text-align: center;
}
.send-page .transaction-done .form p {
  font-size: 0.9rem;
}
.send-page .transaction-done button {
  border: unset;
  background: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 8px 0;
  font-weight: 700;
}
.send-page .navigation {
  margin-top: 10px;
  width: calc(100vw - 1.8rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.send-page .navigation button {
  border: unset;
  background-color: transparent;
  background: transparent;
  font-size: 1rem;
  color: #0d6efd;
  padding: 0.5rem;
  border-radius: 10px;
}
.scanner-div .container {
  border-radius: 16px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);
  position: relative;
  padding-top: 1rem;
  margin-top: 0.5rem;
}
.scanner-div .container .larr {
  font-size: 1.5rem;
}
.scanner-div .heading-scanner {
  display: flex;
  align-items: center;
}
.scanner-div .heading-scanner h3 {
  display: inline-block;
  margin: 0;
  margin-left: 10px;
}
.scanner-div .camera {
  margin: 8px 0px;
}
.scanner-div .camera video {
  width: calc(100vw - 50px);
}
.scanner-div .btn {
  text-align: center;
  z-index: 10;
  margin-top: 8px;
}
.scanner-div .btn button {
  width: 100%;
  border: none;
  padding: 7px 10px;
  background-color: #0d6efd;
  color: #fff;
  font-weight: 600;
  border-radius: 0.3rem;
  font-size: 0.7rem;
  text-align: center;
  vertical-align: middle;
}
.showError {
  overflow-x: hidden;
}
.showError .heading {
  font-size: 1.1rem;
  text-align: center;
  margin-top: 10px;
}
.showError .error-img {
  margin: auto;
  height: 100px;
  width: 100px;
  margin-top: 15px;
}
.showError .error-img img {
  width: 100%;
  height: 100%;
}
.showError p {
  width: 100%;
  word-break: break-all;
}
.showError .button {
  border: unset;
  background: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 8px 0;
  font-weight: 700;
  color: #000;
}

.setting-screen {
  background-color: #010006db;
  min-height: calc(100vh - 2rem);
  padding: 1.5rem 0.9rem;
  overflow: hidden;
  padding-top: 0.5rem;
  color: #fff;
}
.setting-screen .content .heading {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.setting-screen .content .heading button {
  border: unset;
  background-color: unset;
  color: #fff;
  outline: unset;
  border-radius: 10px;
  padding: 0.5rem;
  font-weight: 700;
}
.setting-screen .content .btn {
  margin-top: 1rem;
}
.setting-screen .content .btn button {
  width: 100%;
  text-align: left;
  padding: 0.6rem 0.9rem;
  border-radius: 10px;
  margin: 0.6rem 0;
  display: block;
  outline: unset;
  font-size: 0.8rem;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);
  color: #fff;
}
.setting-screen .items:focus {
  box-shadow: 1px 1px 12px 2px #fff !important;
}

.switch-account-page {
  background-color: #010006db;
  min-height: calc(100vh - 2.5rem);
  height: calc(100vh - 2.5rem);
  padding: 1.5rem 1rem;
  overflow: hidden;
  color: #fff;
  padding-top: 1rem;
}
.switch-account-page .header {
  height: 35vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.switch-account-page .header span {
  font-weight: 500;
  font-size: 0.8rem;
}
.switch-account-page .header button {
  font-size: 14px;
  text-decoration: none;
  position: fixed;
  top: 19px;
  left: 15px;
  color: white;
  background: transparent;
  border: unset;
  padding: 5px;
  border-radius: 10px;
}
.switch-account-page .cards {
  margin-top: 20px;
}
.switch-account-page .cards .card {
  margin-top: 15px;
  border: 1px solid #eee;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}
.switch-account-page .cards .card .formate-name {
  padding: 0.5rem;
  font-size: 0.8rem;
}
.switch-account-page .cards .card button {
  background-color: #0d6efd;
  color: #fff;
  border: unset;
  padding-left: 25px !important;
  padding-right: 25px !important;
  border-radius: 10px;
}
.switch-account-page .cards .card button.selected {
  background-color: #2759a4;
}
.switch-account-page .form input {
  border-radius: 5px;
  width: 100%;
  border: 1px solid #fff;
  text-align: center;
  padding: 8px 0;
  background: transparent;
  color: #fff;
}
.switch-account-page .form button {
  margin-top: 12px;
  border: unset;
  background: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 8px 0;
}
.switch-account-page .form .background {
  font-size: 14px;
  text-decoration: none;
  position: fixed;
  top: 19px;
  left: 15px;
  color: white;
  background: transparent;
  border: unset;
  padding: 5px;
  border-radius: 10px;
  width: auto;
}
.switch-account-page .form .data {
  margin: 5px 0;
}
.switch-account-page .form .data small {
  margin: 0;
}
.switch-account-page .form .data p {
  font-size: 0.8rem;
  margin: 0;
  text-align: justify;
  word-wrap: break-word;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 3px;
}

.connect-ton-page {
  background-color: #010006db;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  text-align: center;
}
.connect-ton-page .connect-ton-page-inner {
  width: 80%;
  display: flex;
  flex-direction: column;
}
.connect-ton-page .connect-ton-page-inner .logos {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
.connect-ton-page .connect-ton-page-inner .logos .logo {
  width: 50px;
  height: 50px;
  text-align: center;
}
.connect-ton-page .connect-ton-page-inner .logos .logo img {
  width: 100%;
  height: 100%;
}
.connect-ton-page .connect-ton-page-inner .logos .logo.arrow img {
  transform: rotate(180deg);
  filter: invert(100%);
}
.connect-ton-page .connect-ton-page-inner .summary {
  margin-bottom: 10px;
}
.connect-ton-page .connect-ton-page-inner .summary .heading {
  font-size: 1.1rem;
  color: #fff;
}
.connect-ton-page .connect-ton-page-inner .summary p {
  word-break: break-all;
  font-size: 0.8rem;
  color: #fff;
  text-align: start;
  margin: 5px 0;
}
.connect-ton-page .connect-ton-page-inner .form input {
  border-radius: 5px;
  width: 100%;
  border: 1px solid #fff;
  text-align: center;
  padding: 8px 0;
  background: transparent;
  color: #fff;
}
.connect-ton-page .connect-ton-page-inner .form button {
  margin-top: 12px;
  border: unset;
  background: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 8px 0;
}
.connect-ton-page .connect-ton-page-inner .form p {
  font-size: 1rem;
  color: #fff;
}
.connect-ton-page button {
  border: unset;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
}
.connect-ton-page .connect-wallet-btn {
  background-color: #0d6efd;
  color: #fff;
  padding-left: 25px !important;
  padding-right: 25px !important;
  margin: auto;
}
.connect-ton-page .cancel-btn {
  background: transparent;
  color: #fff;
  margin-top: 0.5rem;
}

