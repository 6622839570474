.switch-account-page {
  background-color: #010006db;
  min-height: calc(100vh - 2.5rem);
  height: calc(100vh - 2.5rem);
  padding: 1.5rem 1rem;
  overflow: hidden;
  color: #fff;
  padding-top: 1rem;
}
.switch-account-page .header {
  height: 35vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.switch-account-page .header span {
  font-weight: 500;
  font-size: 0.8rem;
}
.switch-account-page .header button {
  font-size: 14px;
  text-decoration: none;
  position: fixed;
  top: 19px;
  left: 15px;
  color: white;
  background: transparent;
  border: unset;
  padding: 5px;
  border-radius: 10px;
}
.switch-account-page .cards {
  margin-top: 20px;
}
.switch-account-page .cards .card {
  margin-top: 15px;
  border: 1px solid #eee;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}
.switch-account-page .cards .card .formate-name {
  padding: 0.5rem;
  font-size: 0.8rem;
}
.switch-account-page .cards .card button {
  background-color: #0d6efd;
  color: #fff;
  border: unset;
  padding-left: 25px !important;
  padding-right: 25px !important;
  border-radius: 10px;
}
.switch-account-page .cards .card button.selected {
  background-color: #2759a4;
}
.switch-account-page .form input {
  border-radius: 5px;
  width: 100%;
  border: 1px solid #fff;
  text-align: center;
  padding: 8px 0;
  background: transparent;
  color: #fff;
}
.switch-account-page .form button {
  margin-top: 12px;
  border: unset;
  background: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 8px 0;
}
.switch-account-page .form .background {
  font-size: 14px;
  text-decoration: none;
  position: fixed;
  top: 19px;
  left: 15px;
  color: white;
  background: transparent;
  border: unset;
  padding: 5px;
  border-radius: 10px;
  width: auto;
}
.switch-account-page .form .data {
  margin: 5px 0;
}
.switch-account-page .form .data small {
  margin: 0;
}
.switch-account-page .form .data p {
  font-size: 0.8rem;
  margin: 0;
  text-align: justify;
  word-wrap: break-word;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 3px;
}
